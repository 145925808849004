<template>
<v-card flat tile width="100%">
    <div v-if="fullPriceBlock">
        <h2>{{ openProductData.name_ukr }} <span v-if="productInfoData.is_priceoff" class="font-weight-thin">(SN {{productInfoData.priceoff_sn}})</span></h2>
        <div class="caption mt-2 mb-4">Код: <span class="ml-2"> {{ openProductData.typhoon_id }} </span></div>
    </div>
    <!-- {{actionsGifts}} -->
    <!-- {{giftsData}} -->
    <!-- {{groupGifts}}
    {{productInfoData.prodset_id}} - {{productInfoData.group_id}} -->
    <!-- {{actionProdset}} -->
    <!-- {{actionProdset}} -->
    <v-list class="body-2 my-3" v-if="$vuetify.breakpoint.smAndUp || fullPriceBlock">
        <v-list-item class="pa-0" v-if="contactInfo">
            <v-list-item-avatar class="pr-4">
                <v-icon v-if="avType() == `available`" color="accent2">check_circle_outline</v-icon>
                <v-icon v-if="avType() == `reserve`" color="third">help_outline</v-icon>
                <v-icon v-if="avType() == `in_transit`" color="link2">local_shipping</v-icon>
                <v-icon v-if="avType() == `on_demand`" color="grey darken-1">help_outline</v-icon>
            </v-list-item-avatar>
            <!-- <div>{{avType()}}</div> -->
            <v-list-item-content>
                <div>Наявність:</div>
                <div v-for="(v, key) in contactInfo.availableRegionWhs" :key="key" v-if="hselect('availability_'+v.id)"><span class="caption grey--text">Склад {{v.name}}:</span> <b>{{productInfoData['availability_'+v.id]}}</b></div>
                <div v-for="(v, key) in contactInfo.availableSatWhs" :key="key+'c'" v-if="hselect('availability_sat_'+v.id)"><span class="caption grey--text">Склад {{v.name}}:</span> <b>{{productInfoData['availability_sat_'+v.id]}}</b></div>
                <div v-for="(v, key) in contactInfo.availableDcWhs" :key="key+'a'" v-if="hselect('availability_dc_'+v.id)"><span class="caption grey--text">РЛЦ {{v.name}}:</span> <b>{{productInfoData['availability_dc_'+v.id]}}</b></div>

            </v-list-item-content>
        </v-list-item>
    </v-list>

    <v-card flat tile width="100%" v-if="contactInfo.groups && contactInfo.groups.cl_corp_action && contactInfo.groups.cl_xml && contactInfo.isConfirmed && ($vuetify.breakpoint.smAndUp || fullPriceBlock)">
        <v-card class="amber lighten-5 my-4 mb-8" width="100%" elevation="0" v-if="((actionslist && actionslist.length) || (specActionslist && specActionslist.length) ) && !productInfoData.is_priceoff">
            <v-card-title class="subtitle-1 mb-4 amber lighten-4">
                <span class="primary--text font-weight-bold text-uppercase">Акції</span>
                <v-spacer></v-spacer>
                <v-icon class="primary--text">card_giftcard</v-icon>
            </v-card-title>
            <v-card-text>
                <div v-if="actionslist && actionslist.length">
                    <div class="primary--text subtitle-1 font-weight-bold">На товар діють акції (застосування через менеджера):</div>
                    <v-list-item class="" v-for="(action, id) in actionslist" :key="id">
                        <v-list-item-content>
                            <v-list-item-title class="text-wrap"><a target="_blank" :href="`/action/${action.id}`">{{entities.decode(action.b2b_name || action.name)}}</a></v-list-item-title>
                            <v-list-item-subtitle v-html="action.announce" class="caption"></v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                </div>
                <div id="actionsBlock">
                    <div class="mt-2" v-show="specActionslist && specActionslist.length">
                        <div class="primary--text subtitle-1 font-weight-bold mb-6">Купити по акції:</div>
                        <v-radio-group v-for="(action, id) in specActionslist" :key="id" v-model="actionGroup" @change="groupSelect(action.prodset_id)" class="mt-n6">
                            <v-list-item class="pa-0 px-sm-2">
                                <v-list-item-avatar>
                                    <v-radio :value="action.group_id"></v-radio>
                                </v-list-item-avatar>
                                <v-list-item-content>
                                    <v-list-item-title class="text-wrap" v-if="action.action_id"><a target="_blank" :href="`/action/${action.action_id}`">{{entities.decode(action.b2b_name || action.name)}}</a></v-list-item-title>
                                    <v-list-item-title class="text-wrap text-weight-bold" v-else>{{entities.decode(action.b2b_name || action.name)}}</v-list-item-title>
                                    <v-list-item-subtitle v-if="action.action_id" class="caption">
                                        {{actionText(action)}}
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                                <v-list-item-action>
                                    {{(action.price || productInfoData.price_uah_without_action).toLocaleString('ru-RU')}} ₴
                                </v-list-item-action>
                            </v-list-item>

                            <v-list-item v-if="action.is_gift_action " class="mt-n2 pa-0 px-sm-2">
                                <v-list-item-avatar>
                                </v-list-item-avatar>
                                <v-list nav dense class="pa-0 amber lighten-5">
                                    <!-- <pre>{{actionsGifts[action.prodset_id]}}</pre> -->
                                    <div v-if="actionsGifts[action.prodset_id].length == 1" class="primary--text caption font-weight-bold ">Подарунок: </div>
                                    <div v-else class="primary--text caption font-weight-bold ">Обери в подарунок: </div>
                                    <div v-for="(prodset, id) in actionsGifts[action.prodset_id]" :key="id" class="gift-group">
                                        <div v-for="(el, id) in prodset" :key="id" class="d-flex caption mt-2">
                                            <!-- {{el.group_id}} - {{el.prodset_id}}  -->
                                            <!-- <v-checkbox :disabled="actionGroup == action.group_id ? false : true" on-icon="mdi-gift" off-icon="" hide-details v-model="gift" :value="el.typhoon_id" :readonly="el.typhoon_id == gift" @click="giftChecked(action.prodset_id)" class="mt-n2"> -->
                                            <v-checkbox :disabled="actionGroup == action.group_id ? false : true" on-icon="mdi-gift" off-icon="mdi-checkbox-blank-outline" hide-details v-model="groupGifts[el.group_id]" :value="el.typhoon_id" :readonly="el.typhoon_id == groupGifts[el.group_id]" @change="giftChecked(el)" class="mt-n2">
                                            </v-checkbox>
                                            <a :href="`/product/${el.typhoon_id}`" target="_blank">{{entities.decode(el.name_ukr)}}</a>
                                            <span class="ml-2 text-no-wrap">{{el.qty}} шт.</span>
                                        </div>
                                        <div class="plus pl-2 mt-1">+</div>
                                    </div>
                                </v-list>
                            </v-list-item>
                        </v-radio-group>
                    </div>
                </div>
            </v-card-text>
        </v-card>
    </v-card>

    <div v-if="$vuetify.breakpoint.smAndUp || fullPriceBlock" class="body-2">Ціна:</div>
    <div class="d-flex align-end justify-space-between justify-sm-start">
        <div class="d-flex align-start flex-column justify-end mr-sm-6">
            <template v-if="hselect('price_uah')">
                <div class="">
                    <div v-if="(productInfoData.price_uah < productInfoData.price_uah_without_action && (productInfoData.is_actions || productInfoData.is_priceoff)) " class="grey--text" style="text-decoration: line-through; white-space: nowrap;">
                        {{productInfoData.price_uah_without_action && productInfoData.price_uah_without_action.toLocaleString('ru-RU')}} <span class="body-2">₴</span>
                    </div>
                    <div :class="'d-flex flex-no-wrap align-end headline font-weight-bold '
                + (productInfoData.price_uah != productInfoData.price_uah_without_action && productInfoData.is_actions ? 'mr-2 mr-md-4 third--text ' : '')
                + (productInfoData.is_priceoff ? 'red--text ' : '')
                ">
                        <v-btn class="mb-1" title="Ціна контролюється вендором" v-if="productInfoData.is_price_roc" color="primary" small icon @click.stop="open_roc_control_dialog(productInfoData.price_without_roc_uah.toLocaleString('ru-RU'))">
                            <v-icon>mdi-alert-circle</v-icon>
                        </v-btn>
                        {{ productInfoData.price_uah && productInfoData.price_uah.toLocaleString('ru-RU') }} <span class="body-2 mb-1 ml-1">₴</span>
                    </div>
                </div>
            </template>

            <template v-if="!hselect('price_uah') && hselect('price_rrc')">
                <div v-if="!$vuetify.breakpoint.xs" class="body-2 mb-3">РРЦ:</div>
                <div class="link--text">
                    <div v-if="productInfoData.price_rrc < productInfoData.price_rrc_without_promo" class="grey--text" style="text-decoration: line-through; white-space: nowrap;">
                        {{productInfoData.price_rrc_without_promo && productInfoData.price_rrc_without_promo.toLocaleString('ru-RU')}} <span class="body-2">₴</span>
                    </div>
                    <div :class="'d-flex flex-no-wrap align-end headline font-weight-bold '
                + (productInfoData.price_rrc != productInfoData.price_rrc_without_promo ? 'mr-2 mr-md-4 third--text ' : '')
                ">
                        {{ productInfoData.price_rrc && productInfoData.price_rrc.toLocaleString('ru-RU') }} <span class="body-2 mb-1 ml-1">₴</span>
                    </div>
                </div>
            </template>

        </div>

        <div>
            <v-card tile elevation="0" height="20">
                <div v-if="productInfoData.act_qty > 1 && productInfoData.single_act_cond && productInfoData.is_promo_action && !productInfoData.is_priceoff && !productInfoData.typhoon_check_lot" class="caption orange--text text-center">Кратно {{productInfoData.act_qty}} шт.</div>
                <div v-else>
                    <div v-if="productInfoData.pack > 1  &&  !productInfoData.is_du && !productInfoData.is_priceoff" class="caption orange--text text-center">Кратно {{productInfoData.pack}} шт.</div>
                </div>
            </v-card>

            <div class="d-flex justify-center flex-grow-1 flex-sm-grow-0 align-center">
                <v-btn x-medium icon @click.stop="minus(productInfoData)">
                    <v-icon>mdi-minus</v-icon>
                </v-btn>
                <input v-model="productInfoData.cnt" @change="recalcMultipack(productInfoData)" class="inp-cnt">
                <v-btn x-medium icon @click.stop="plus(productInfoData)">
                    <v-icon>mdi-plus</v-icon>
                </v-btn>
            </div>
        </div>

        <div class="d-flex flex-column align-end justify-end">
            <v-card tile elevation="0" height="20">
                <div v-if="inShoppingCart > 0" class="caption accent2--text text-right">Товар вже у кошику</div>
            </v-card>
            <div class="pa-0 d-flex justify-space-between align-center">
                <!-- {{actionProdset}} -->
                <v-btn color="accent2" class="white--text ml-2 ml-md-5" @click.prevent.stop="addToShoppingCart(productInfoData)">
                    <v-icon v-if="inShoppingCart" color="white">mdi-cart</v-icon>
                    <v-icon v-else color="white">mdi-cart-outline</v-icon>
                    Купити
                </v-btn>
            </div>
        </div>
    </div>

    <div class="mt-4" v-if="hselect('price_uah') || hselect('markup') && ($vuetify.breakpoint.smAndUp || fullPriceBlock)">
        <div class="caption grey--text darken-2">
            <template v-if="hselect('price_uah') && hselect('price_rrc')">
                РРЦ:
                <span class="ml-2"> {{ productInfoData && productInfoData.price_rrc && productInfoData.price_rrc.toLocaleString('ru-RU') }} ₴</span>
                <span v-if="productInfoData.price_rrc < productInfoData.price_rrc_without_promo" class="ml-2" style="text-decoration: line-through; white-space: nowrap;">{{ productInfoData && productInfoData.price_rrc_without_promo && productInfoData.price_rrc_without_promo.toLocaleString('ru-RU') }} ₴</span>
            </template>
            <template v-if="hselect('markup')">
                <span v-if="hselect('price_uah') && hselect('price_rrc')" class="mx-2">|</span>РРН: <span class="ml-2"> {{ productInfoData && productInfoData.markup && productInfoData.markup.toLocaleString('ru-RU') }} % </span>
            </template>
        </div>
    </div>

    <!-- Контроль РОЦ -->
    <v-dialog v-model="roc_control_dialog" max-width="290" overlay-opacity="1" overlay-color="grey">
        <v-card>
            <v-card-title class="headline">Ваша цiна:</v-card-title>

            <v-card-text class="headline second--text text-center my-4">
                {{ roc_control_price }} <span class="body-2">₴</span>
            </v-card-text>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" text @click="roc_control_dialog = false">
                    OK
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>

</v-card>
</template>

<script>
import {
    mapActions
} from 'vuex'

const Entities = require('html-entities').XmlEntities

export default {
    data() {
        return {
            inShoppingCart: 0,
            roc_control_dialog: false,
            roc_control_price: NaN,
            entities: new Entities(),
        }
    },
    computed: {
        productInfoData: {
            get: function () {
                return this.$store.state.catalog.productInfoData
            },
            set: function (v) {
                this.$store.commit('setProductInfoData', v)
            }
        },
        actionGroup: {
            get: function () {
                return this.$store.state.catalog.actionGroup
            },
            set: function (v) {
                this.$store.commit('setActionGroup', v)
            }
        },
        actionProdset: {
            get: function () {
                return this.$store.state.catalog.actionProdset
            },
            set: function (v) {
                this.$store.commit('setActionProdset', v)
            }
        },
        giftsData: {
            get: function () {
                return this.$store.state.catalog.giftsData
            },
            set: function (v) {
                this.$store.commit('setGiftsData', v)
            }
        },
        groupGifts: {
            get: function () {
                return this.$store.state.catalog.groupGifts
            },
            set: function (v) {
                this.$store.commit('groupGifts', v)
            }
        },
        fullPriceBlock: {
            get: function () {
                return this.$store.state.catalog.fullPriceBlock
            },
            set: function (v) {
                this.$store.commit('setFullPriceBlock', v)
            }
        },
        multipack() {
            return this.productInfoData.pack > 1 && !this.productInfoData.is_du && !this.productInfoData.is_priceoff ? true : false
        },
        headers() {
            return this.$store.state.main.headers
        },
        specActionslist() {
            return this.$store.state.actions.specList
        },
        actionslist() {
            return this.$store.state.actions.list
        },
        contactInfo() {
            return this.$store.state.main.contactInfo
        },
        openProductData() {
            return this.$store.state.catalog.openProductData
        },
        actionsGifts() {
            return this.$store.state.actions.actionsGifts
        }
    },
    created() {
        this.getShoppingCart()
    },
    methods: {
        ...mapActions(['touch', 'getShoppingCartData', 'getProductInfo', 'setShoppingCartData', 'setSnackbar']),
        plus(item) {
            item.cnt = Number(item.cnt)
            if (item.act_qty > 1 && item.single_act_cond && item.is_promo_action && !item.is_priceoff && !item.typhoon_check_lot) {
                item.cnt = item.cnt + item.act_qty
                item.cnt = item.cnt - item.cnt % item.act_qty
            } else if (item.pack > 1 && !item.is_du && !item.is_priceoff) {
                item.cnt = item.cnt + item.pack
                item.cnt = item.cnt - item.cnt % item.pack
            } else {
                item.cnt++
            }
            this.recalcGiftsQty(item)
        },
        minus(item) {
            if (item.act_qty > 1 && item.single_act_cond && item.is_promo_action && !item.is_priceoff && !item.typhoon_check_lot) {
                item.cnt = item.cnt - item.act_qty
                if (item.cnt <= 0) item.cnt = 0
                item.cnt = item.cnt - item.cnt % item.act_qty
                if (item.cnt == 0 && item.cntIn > 0) item.cnt = item.act_qty
            } else if (item.pack > 1 && !item.is_du && !item.is_priceoff) {
                item.cnt = item.cnt - item.pack
                if (item.cnt <= 0) item.cnt = 0
                item.cnt = item.cnt - item.cnt % item.pack
                if (item.cnt == 0 && item.cntIn > 0) item.cnt = item.pack
            } else {
                if (item.cntIn > 0 && --item.cnt < 1) {
                    item.cnt = 1
                }
                if (item.cntIn == 0 && --item.cnt < 0) {
                    item.cnt = 0
                }
            }
            this.recalcGiftsQty(item)
        },
        recalcGiftsQty(item) {
            if (this.giftsData[item.prodset_id]) {
                for (let group_id in this.giftsData[item.prodset_id]) {
                    const el = this.giftsData[item.prodset_id][group_id]
                    let qty = el.qty
                    if (!item.typhoon_check_lot) {
                        qty = (item.cnt / item.act_qty) * el.qty
                    }
                    this.$set(el, 'qty_total', qty)
                }
            }
        },
        open_roc_control_dialog(v) {
            this.roc_control_dialog = true
            this.roc_control_price = v
        },
        async getShoppingCart() {
            let shoppingCart = []
            try {
                const scObj = await this.getShoppingCartData() || {}
                shoppingCart = scObj.data || []
                this.giftsData = scObj.gifts_data || {}
            } catch (error) {
                console.error(error)
            }

            let exists = false
            for (let item of shoppingCart) {
                if (item.typhoon_full_id == this.productInfoData.typhoon_full_id) {
                    this.inShoppingCart = item.cnt
                    this.$set(this.productInfoData, 'cnt', item.cnt)
                    this.$set(this.productInfoData, 'cntIn', item.cnt)
                    exists = true
                }
            }
            if (!exists) {
                this.inShoppingCart = 0
                this.$set(this.productInfoData, 'cnt', 0)
                this.$set(this.productInfoData, 'cntIn', 0)
            }
        },
        recalcMultipack(item) {
            item.cnt = Number(item.cnt)
            // Если колво/В уп (шт) имеет остаток, то колво = В уп (шт.) * (целое от (колво/В уп (шт)) + 1)
            if (item.act_qty > 1 && item.single_act_cond && item.is_promo_action && !item.is_priceoff && !item.typhoon_check_lot && item.cnt % item.act_qty && !item.typhoon_check_lot) {
                item.cnt = item.act_qty * (Number.parseInt(item.cnt / item.act_qty) + 1)
            } else if (item.pack > 1 && !item.is_du && !item.is_priceoff && item.cnt % item.pack) {
                item.cnt = item.pack * (Number.parseInt(item.cnt / item.pack) + 1)
            }
            this.recalcGiftsQty(item)
        },
        async addToShoppingCart(item) {
            // this.groupSelect(this.actionProdset)

            if (item.cnt == 0) {
                this.plus(item)
            } else {
                this.recalcMultipack(item)
            }
            let shoppingCart = []
            let currentGiftsData = {}
            try {
                const scObj = await this.getShoppingCartData() || {}
                shoppingCart = scObj.data
                currentGiftsData = scObj.gifts_data
            } catch (error) {
                console.error(error)
            }
            let exists = false
            for (let i in shoppingCart) {
                if (item.typhoon_full_id == shoppingCart[i].typhoon_full_id) {
                    exists = true
                    this.$set(shoppingCart[i], 'cnt', item.cnt)
                    this.$set(shoppingCart[i], 'group_id', this.actionGroup)
                    this.$set(shoppingCart[i], 'prodset_id', this.actionProdset)
                }
            }
            if (!exists) {
                shoppingCart.push(item)
            }

            for (let prodset_id in this.actionsGifts) {
                delete currentGiftsData[prodset_id]
            }
            const giftsData = Object.assign(currentGiftsData, this.giftsData)

            this.touch()
                .then(() => {
                    this.setShoppingCartData({
                            shoppingCart,
                            giftsData
                        })
                        .then(() => {
                            this.$store.commit('setShoppingCartCounter', shoppingCart.length)
                            if (!this.hselect('price_uah')) {
                                this.setSnackbar(['success', 'Товари додано в кошик'])
                            } else {
                                this.$store.commit('setShoppingCartDialog', true)
                                this.$store.commit('setShoppingCartDialogTab', 0)
                            }
                        })
                })
        },
        recalcDefaultGifts() {
            for (let prodset_id in this.actionsGifts) {
                for (let group_id in this.actionsGifts[prodset_id]) {
                    this.$set(this.groupGifts, group_id, this.actionsGifts[prodset_id][group_id][0].typhoon_id)
                }
            }
        },
        getLocalStorage(f) {
            let value = ''
            try {
                value = localStorage[f] ? JSON.parse(localStorage[f]) : ''
            } catch (error) {
                console.error(error)
                return ''
            }
            return value
        },
        setLocalStorage(f, v) {
            localStorage[f] = JSON.stringify(v)
        },
        hselect(v) {
            return this.headers.filter(el => el.value == v && el.selected).length ? true : false
        },
        groupSelect(prodsetId) {
            const cnt = this.productInfoData.cnt
            const [typhoon_id, type, priceoff_id] = this.$route.params.id.split('_')
            this.getProductInfo([typhoon_id, type, priceoff_id, this.actionGroup, true])
                .then(data => {
                    if (!this.fullPriceBlock) {
                        this.$vuetify.goTo('#actionsBlock')
                    }
                    this.recalcDefaultGifts()
                    this.giftsData = {}
                    this.actionProdset = prodsetId
                    if (this.actionsGifts[prodsetId]) {
                        const act_qty = this.productInfoData.act_qty
                        for (let group_id in this.actionsGifts[prodsetId]) {
                            const prodset_id = this.actionsGifts[prodsetId][group_id][0].prodset_id

                            if (!this.giftsData[prodset_id]) {
                                this.$set(this.giftsData, prodset_id, {})
                            }
                            this.$set(this.giftsData[prodset_id], group_id, this.actionsGifts[prodsetId][group_id][0])
                            const el = this.giftsData[prodset_id][group_id]
                            let qty = el.qty
                            if (!this.productInfoData.typhoon_check_lot) {
                                qty = (cnt / act_qty) * el.qty
                            }
                            this.$set(el, 'qty_total', qty)
                        }
                    } else {
                        this.giftsData = {}
                    }
                    this.$set(this.productInfoData, 'cnt', cnt)
                })
                .catch(error => {
                    console.error(error)
                })
        },
        avType() {
            if (this.productInfoData.status == 'available') {
                return 'available'
            } else if (this.productInfoData.status == 'in_transit') {
                return 'in_transit'
            } else if (this.productInfoData.status == 'on_demand') {
                return 'on_demand'
            } else if (this.productInfoData.status == 'reserve') {
                return 'reserve'
            }
            return ''
        },
        round(value, precision) {
            const multiplier = Math.pow(10, precision || 0)
            if (value > 100) {
                return Math.ceil(value)
            } else {
                return Math.ceil(value * multiplier) / multiplier
            }
        },
        actionText(item) {
            let text = ''
            const checkLotText = item.typhoon_check_lot ? 'від ' : ''
            if (item.is_spec_action) {
                if (item.act_qty == 1) {
                    text = `Купи за супер ціною!`
                } else {
                    if (item.single_act_cond) {
                        text = `Купи ${checkLotText}${item.act_qty} шт. за супер ціною!`
                    } else {
                        text = `Купи з асортименту ${checkLotText}${item.act_qty} шт. за супер ціною!`
                    }
                }
            } else if (item.is_gift_action) {
                if (item.act_qty == 1) {
                    text = `Купи та отримай подарунок!`
                } else {
                    if (item.single_act_cond) {
                        text = `Купи ${checkLotText}${item.act_qty} шт. та отримай подарунок!`
                    } else {
                        text = `Купи з асортименту ${checkLotText}${item.act_qty} шт. та отримай подарунок!`
                    }
                }
            }
            return text
        },
        giftChecked(prodset) {
            this.giftsData = {}
            this.actionProdset = prodset.prodset_id
            const counter = {}
            const actionsGifts = JSON.parse(JSON.stringify(this.actionsGifts))
            if (actionsGifts[this.actionProdset]) {
                for (let group_id in actionsGifts[this.actionProdset]) {
                    for (let el of actionsGifts[this.actionProdset][group_id]) {
                        if (this.groupGifts[group_id] == el.typhoon_id) {
                            if (!counter[el.typhoon_id]) {
                                if (!this.giftsData[el.prodset_id]) {
                                    this.$set(this.giftsData, el.prodset_id, {})
                                }
                                this.$set(this.giftsData[el.prodset_id], group_id, el)
                                counter[el.typhoon_id] = 0
                            }
                            counter[el.typhoon_id] += Number(el.qty)
                        }
                    }
                }
                const cnt = this.productInfoData.cnt
                const act_qty = this.productInfoData.act_qty
                for (let group_id in this.giftsData[this.actionProdset]) {
                    const el = this.giftsData[this.actionProdset][group_id]
                    el.qty = counter[el.typhoon_id]
                    let qty = counter[el.typhoon_id]
                    if (!this.productInfoData.typhoon_check_lot) {
                        qty = (cnt / act_qty) * counter[el.typhoon_id]
                    }
                    this.$set(el, 'qty_total', qty)
                }
            }
        }
    },
    watch: {
        '$store.state.main.shoppingCartDialog'(v) {
            if (!v) {
                setTimeout(() => {
                    this.getShoppingCart()
                }, 1000);

            }
        },
        productInfoData(v) {}
    }

}
</script>

<style scoped>
.v-data-table.card.gifts tbody tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
    background: #fff8e1 !important;
}

.gift-group:last-child .plus {
    display: none;
}
</style>
