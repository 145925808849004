<template>
<ProductPage />
</template>

<script>
import {
    mapActions
} from 'vuex'

import ProductPage from '../components/ProductPage.vue'

export default {
    data: () => ({}),
    components: {
        ProductPage
    },
    methods: {
        ...mapActions(['productPageVisited', 'touch']),
    },
    mounted() {
        this.touch()
            .then(() => {
                this.productPageVisited(this.$route.params.id)
            })
            .catch(error => console.log(error))
    }
}
</script>
